import { Box, Flex, Link } from "gestalt";
import type { Assets } from "../../__generated__/assets";

export default function RetentionLogo() {
  return (
    <Link href="/">
      <Flex alignItems="center" justifyContent="start">
        <Box marginEnd={2}>
          <img
            alt="retention script"
            src={"/retention-script-logo.png" satisfies Assets}
            width={40}
          />
        </Box>
        <picture>
          <source
            media="(prefers-color-scheme: light)"
            srcSet={"/retention-script-letter-light.svg" as Assets}
          />
          <source
            media="(prefers-color-scheme: dark)"
            srcSet={"/retention-script-letter-dark.svg" as Assets}
          />
          <img
            alt="Retention Script"
            src={"/retention-script-letter-dark.svg" as Assets}
            width="100px"
          />
        </picture>
      </Flex>
    </Link>
  );
}
